import React from 'react';
import {
  makeStyles
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SubmitScoreId(props) {
  const classes = useStyles();
  const [scoreValue, setScoreValue] = React.useState(0);
  const [pageValue, setPageValue] = React.useState(0);
  const onSubmit = (event) => {
    props.onSubmit(scoreValue, pageValue, 0);
  };
  const handleScoreChange = (event) => {
    let str = event.target.value;
    setScoreValue(str);
  };
  const handlePageChange = (event) => {
    let str = event.target.value;
    setPageValue(str);
  };
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Score ID"
        type="number"
        value={scoreValue > 0 ? scoreValue : ""}
        onChange={handleScoreChange}
        inputProps={{ 'aria-label': 'Score ID' }}
      />
      <InputBase
        className={classes.input}
        placeholder="Page ID"
        type="number"
        value={pageValue}
        onChange={handlePageChange}
        inputProps={{ 'aria-label': 'Page ID' }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={onSubmit}>
        <DirectionsIcon />
      </IconButton>
    </Paper>
  );
}
