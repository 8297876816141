import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import IconButton from '@material-ui/core/IconButton';
import ImageInfo from '../components/imageInfo';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SubmitConfirm from '../components/SubmitConfirm';


export default class ImageDoubleScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0
    };
  }

  componentDidMount() {}

  render() {
    let url = "/read/score/mq/image/view?name=" + this.props.fileName
    return (
      <div className="image-double">
        <div className="title-panel">
          <IconButton
            color="primary"
            onClick={this.props.onClickReturn}>
            <ArrowBackIosIcon style={{ fontSize: 30}}></ArrowBackIosIcon>
          </IconButton>
        </div>
        <div className="double-panel double-left">
          <img src={url}  alt=""/>
        </div>
        <div className="double-panel double-right">
          {
            this.state.index > 0 ?
            <IconButton
              color="primary"
              className="direction-icon icon-left"
              onClick={this.onPrevImage.bind(this)}>
              <ArrowLeftIcon style={{ fontSize: 50}}></ArrowLeftIcon>
            </IconButton> : null
          }
          {
            this.props.images[this.state.index].url ?
            <img src={this.props.images[this.state.index].url} alt=""/> :
            <span className="errMsg">此图片不存在！</span>
          }
          {
            this.state.index < this.props.images.length - 1 ?
            <IconButton
              color="primary"
              className="direction-icon icon-right"
              onClick={this.onNextImage.bind(this)}>
              <ArrowRightIcon style={{ fontSize: 50}}></ArrowRightIcon>
            </IconButton> : null
          }
        </div>
        <div className="info-panel">
          {
            this.props.images.length > 0 ?
            <ImageInfo
              info={this.props.images[this.state.index]}
              index={this.state.index}
              onScoreIdSubmit={this.props.onScoreIdSubmit}
              onIsTrueSubmit={this.props.onIsTrueSubmit}>
            </ImageInfo>
            : null
          }
        </div>
        {this.state.confirmOpen ?
          <SubmitConfirm
            onClose={this.setConfirm.bind(this)}
            content={this.state.confirmContent}></SubmitConfirm>
          : null
        }
      </div>
    )
  }

  onPrevImage() {
    if (this.state.index > 0) {
      this.setState({
        index: this.state.index - 1
      })
    }
  }

  onNextImage() {
    if (this.state.index < this.props.images.length - 1) {
      this.setState({
        index: this.state.index + 1
      })
    }
  }

  setConfirm(status, content) {
    this.setState({
      confirmOpen: status,
      confirmContent: content
    })
  }
}
