import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import SearchScreen from "./searchScreen";
import ResultScreen from "./ResultScreen";
import OriginalScreen from "./OriginalScreen";
import ErrorScreen from "./ErrorScreen";
import Loading from "../components/Loading";
import SubmitConfirm from '../components/SubmitConfirm';


export default class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "home",
      maxTimes: 30,
      imgMaxWidth: 1024
    };
    this.sendSearchRequest = this.sendSearchRequest.bind(this);
  }

  componentDidMount() {
    document.title = '乐谱搜索引擎';
    // this.loading.show()
    // this.searchImage("", "/read/score/search/image1");
  }

  render() {
    let currentPage = this.state.currentPage;
    if (currentPage === "home") {
      return (
        <SearchScreen
          searchImage={this.searchImage.bind(this)}></SearchScreen>
      )
    } else if (currentPage === "result") {
      return (
        <ResultScreen
          ref={(view) => this.result = view}
          images={this.state.images}
          fileName={this.state.fileName}
          searchImage={this.searchImage.bind(this)}
          onClickReturn={this.onReturnHome.bind(this)}
          onShowOriginalImage={this.onShowOriginalImage.bind(this)}
          onScoreIdSubmit={this.onScoreIdSubmit.bind(this)}
          onIsTrueSubmit={this.onIsTrueSubmit.bind(this)}>
        </ResultScreen>
      )
    } else if (currentPage === "original") {
      return (
        <OriginalScreen
          fileName={this.state.fileName}
          onReturnResult={this.onReturnResult.bind(this)}>
        </OriginalScreen>
      )
    } else if (currentPage === "err") {
      return (
        <ErrorScreen
          errMsg={this.state.errMsg}
          fileName={this.state.fileName}
          searchImage={this.searchImage.bind(this)}
          onReturnHome={this.onReturnHome.bind(this)}>
        </ErrorScreen>
      )
    } else if (currentPage === "confirm") {
      return (
        <SubmitConfirm
          onClose={() => this.setConfirm(false)}
          content="正确的ScoreId提交成功！">
        </SubmitConfirm>
      )
    } else if (currentPage === "loading") {
      return (
        <Loading
          ref={(view) => this.loading = view}>
        </Loading>
      )
    }
  }

  onShowOriginalImage() {
    this.setState({
      currentPage: "original"
    })
  }

  onScoreIdSubmit(scoreId, pageId, isTrue) {
    console.log("scoreId", scoreId, this.state.recordId)
    const data = new FormData();
    data.append('recordId', this.state.recordId);
    data.append('scoreId', scoreId);
    data.append('pageId', pageId);
    data.append('isTrue', isTrue);
    console.log("data", data);
    const url = "/read/score/mq/record/scoreid";
    fetch(url, {
      method: 'POST',
      body: data
    }).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson)
      if (scoreId > 0) {
        let content = "正确的ScoreId提交成功！";
        if (responseJson.code === 1) {
          content = responseJson.message;
        }
        this.result.image.setConfirm(true, content);
      }
    }).catch((err) => {
      console.log("Request err", err)
    })
  }

  searchImageResult(requestId) {
    let url = "/read/score/mq/search/result?isUrl=1&requestId=" + requestId;
    fetch(url).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson)
      if (responseJson.data !== null) {
        if (responseJson.data.images && responseJson.data.images.length > 0) {
          let images = responseJson.data.images;
          for (var i = 0; i < images.length; i++) {
            images["isTrue"] = "";
          }
          console.log(images);
          this.setState({
            images: images,
            recordId: responseJson.data.recordId,
            errMsg: responseJson.message,
            currentPage: "result"
          })
        } else {
          console.log(responseJson.message)
          this.setState({
            errMsg: responseJson.message,
            currentPage: "err"
          })
        }
      }
    }).catch((err) => {
      console.log("Request err", err)
    })
  }

  searchImage(fileObj) {
    this.setState({
      currentPage: "loading"
    })
    var that = this;
    var img = new Image();
    var reader = new FileReader();
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    img.onload = function() {
      var imgWidth = this.width;
      var imgHeight = this.height;
      console.log("origin", imgWidth, imgHeight);
      if (imgWidth > that.state.imgMaxWidth) {
        imgHeight = parseInt(that.state.imgMaxWidth / imgWidth * imgHeight);
        imgWidth = that.state.imgMaxWidth;
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        console.log("new", imgWidth, imgHeight);
        context.clearRect(0, 0, imgWidth, imgHeight);
        context.drawImage(img, 0, 0, imgWidth, imgHeight);
        canvas.toBlob(function(blob) {
          that.sendSearchRequest(blob);
        })
      } else {
        that.sendSearchRequest(fileObj);
      }
    }
    reader.onload = function(e) {
      img.src = e.target.result;
    };
    reader.readAsDataURL(fileObj);
  }

  sendSearchRequest(fileObj, url = "/read/score/mq/search/image") {
    const data = new FormData();
    data.append('file', fileObj);
    if (document.domain === "readscore.xiaoyezi.com") {
      data.append('isMq', 1);
    }
    fetch(url, {
      method: 'POST',
      body: data
    }).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson)
      let requestId = responseJson.data.requestId;
      this.setState({
        fileName: responseJson.data.fileName
      })
      let intervalTimes = 1
      this.timer = setInterval(() => {
        if (intervalTimes < this.state.maxTimes) {
          console.log("intervalTimes", intervalTimes);
          if (this.state.currentPage === "loading") {
            this.searchImageResult(requestId);
            intervalTimes += 1;
          } else {
            clearInterval(this.timer);
          }
        } else {
          clearInterval(this.timer);
          this.setState({
            errMsg: "success",
            currentPage: "err"
          })
        }
      }, 1000);
    }).catch((err) => {
      console.log("Request err", err)
    })
  }

  onReturnHome(e) {
    console.log("return")
    this.setState({
      currentPage: "home"
    })
  }

  onReturnResult(e) {
    this.setState({
      currentPage: "result"
    })
  }

  onIsTrueSubmit(index, status) {
    console.log("data", index, status);
    let images = this.state.images;
    images[index].isTrue = status
    this.setState({
      images: images
    });
    const data = new FormData();
    data.append('recordId', this.state.recordId);
    data.append('index', this.result.image.state.index);
    data.append('status', status === "true" ? 1 : 0);
    const url = "/read/score/mq/record/image/status";
    fetch(url, {
      method: 'POST',
      body: data
    }).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson)
    }).catch((err) => {
      console.log("Request err", err)
    })
  }
}
