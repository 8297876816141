import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import Loading from "../components/Loading";
import SearchButtons from "../components/SearchButtons";

const width = document.documentElement.clientWidth
const height = document.documentElement.clientHeight

export default class SearchScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    var search = document.querySelector('#search');
    document.addEventListener("dragenter", function(e) {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    document.addEventListener("dragover", function(e) {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    document.addEventListener("dragleave", function(e) {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    document.addEventListener("drop", this.dragFile.bind(this), false);

    document.addEventListener('paste', this.pasteFile.bind(this), false);
  }

  render() {
    return (
      <div id="search" className="search-screen" style={{width: width, height: height}}>
        <SearchButtons
          onUploadPhoto={this.onUploadPhoto.bind(this)}>
        </SearchButtons>
      </div>
    )
  }

  onUploadPhoto(e) {
    var files = e.target.files;
    if (files.length > 0) {
      this.props.searchImage(files[0]);
    }
  }

  dragFile(e) {
    e.preventDefault();
    e.stopPropagation();
    var df = e.dataTransfer;
    if (df.items !== undefined) {
      for (var i = 0; i < df.items.length; i++) {
        var item = df.items[i];
        if (item.kind === "file" && item.webkitGetAsEntry().isFile) {
          let file = item.getAsFile();
          this.props.searchImage(file);
        }
      }
    } else if (df.files.length > 0) {
      let file = df.files[0];
      this.loading.show();
      this.props.searchImage(file);
    }
  }

  pasteFile(event) {
    console.log("paste", event);
    var items = event.clipboardData && event.clipboardData.items;
    if (items && items.length) {
      // 检索剪切板items
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          let file = items[i].getAsFile();
          console.log(file)
          this.props.searchImage(file);
          return
        }
      }
    }
  }
}
