import React from 'react';
import './App.css';
import HomeScreen from "./pages/HomeScreen"


export default class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <HomeScreen></HomeScreen>
    )
  }
}
