import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import ImageSingleScreen from "./ImageSingleScreen";
import ImageDoubleScreen from "./ImageDoubleScreen";

const width = document.documentElement.clientWidth
const height = document.documentElement.clientHeight


export default class ResultScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  componentDidMount() {}

  render() {
    if (width < height) {
      return (
        <ImageSingleScreen
          ref={(view) => this.image = view}
          images={this.props.images}
          onClickReturn={this.props.onClickReturn}
          onShowOriginalImage={this.props.onShowOriginalImage}
          onScoreIdSubmit={this.props.onScoreIdSubmit}
          onIsTrueSubmit={this.props.onIsTrueSubmit}>
        </ImageSingleScreen>
      )
    } else {
      return (
        <ImageDoubleScreen
          ref={(view) => this.image = view}
          images={this.props.images}
          fileName={this.props.fileName}
          onClickReturn={this.props.onClickReturn}
          onScoreIdSubmit={this.props.onScoreIdSubmit}
          onIsTrueSubmit={this.props.onIsTrueSubmit}>
        </ImageDoubleScreen>
      )
    }
  }
}
