import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import Button from '@material-ui/core/Button';


export default class SearchButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxTimes: 15,
    };

  }

  componentDidMount() {}

  render() {
    return (
      <div className="search-buttons">
        <div className="button">
          <Button variant="contained" color="primary" onClick={this.onClickPhoto.bind(this)}>
            拍照搜索
          </Button>
          <div>
            <input
              id='photo'
              type='file'
              accept='image/*'
              capture="camera"
              style={{"display": "none"}}
              onChange={this.props.onUploadPhoto} />
          </div>
        </div>
        <div className="button">
          <Button variant="contained" color="primary" onClick={this.onClickFile.bind(this)}>
            文件搜索
          </Button>
          <div>
            <input
              id='file'
              type='file'
              accept='image/*'
              style={{"display": "none"}}
              onChange={this.props.onUploadPhoto} />
          </div>
        </div>
      </div>
    )
  }

  onClickPhoto() {
    document.getElementById("photo").click();
  }

  onClickFile() {
    document.getElementById("file").click();
  }
}
