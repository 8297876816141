import React, {
  Component
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


export default class OriginalScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    let url = "/read/score/mq/image/view?name=" + this.props.fileName
    return (
      <div className="image-single">
        <div className="title-panel">
          <IconButton
            id="returnUpper"
            color="primary"
            onClick={this.props.onReturnResult}>
            <ArrowBackIosIcon style={{ fontSize: 30}}></ArrowBackIosIcon>
          </IconButton>
        </div>
        <div className="image-panel">
          <img src={url} alt=""/>
        </div>
      </div>
    )
  }
}
