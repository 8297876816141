import React, {
  Component
} from 'react';
import '../css/searchScreen.css'
import IconButton from '@material-ui/core/IconButton';
import ImageInfo from '../components/imageInfo';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import SubmitConfirm from '../components/SubmitConfirm';


export default class ImageSingleScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      confirmOpen: false
    };
    this.setConfirm = this.setConfirm.bind(this);
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="image-single">
        <div className="title-panel">
          <IconButton
            color="primary"
            onClick={this.props.onClickReturn}>
            <ArrowBackIosIcon style={{ fontSize: 30}}></ArrowBackIosIcon>
          </IconButton>
          <IconButton
            color="primary"
            onClick = {this.props.onShowOriginalImage} >
            <PhotoAlbumIcon></PhotoAlbumIcon>
          </IconButton>
        </div>
        <div className="image-panel">
          {
            this.state.index > 0 ?
            <IconButton
              color="primary"
              className="direction-icon icon-left"
              onClick={this.onPrevImage.bind(this)}>
              <ArrowLeftIcon style={{ fontSize: 50}}></ArrowLeftIcon>
            </IconButton> : null
          }
          {
            this.props.images[this.state.index].url ?
            <img src={this.props.images[this.state.index].url} alt=""/> :
            <div className="no-image"><span className="errMsg">此图片不存在！</span></div>
          }
          {
            this.state.index < this.props.images.length - 1 ?
            <IconButton
              color="primary"
              className="direction-icon icon-right"
              onClick={this.onNextImage.bind(this)}>
              <ArrowRightIcon style={{ fontSize: 50}}></ArrowRightIcon>
            </IconButton> : null
          }
        </div>
        <div className="info-panel">
          {
            this.props.images.length > 0 ?
            <ImageInfo
              info={this.props.images[this.state.index]}
              index={this.state.index}
              onScoreIdSubmit={this.props.onScoreIdSubmit}
              onIsTrueSubmit={this.props.onIsTrueSubmit}>
            </ImageInfo>
            : null
          }
        </div>
        {this.state.confirmOpen ?
          <SubmitConfirm
            onClose={() => this.setConfirm(false)}
            content="正确的ScoreId提交成功！"></SubmitConfirm>
          : null
        }
      </div>
    )
  }

  onPrevImage() {
    if (this.state.index > 0) {
      this.setState({
        index: this.state.index - 1
      })
    }
  }

  onNextImage() {
    if (this.state.index < this.props.images.length - 1) {
      this.setState({
        index: this.state.index + 1
      })
    }
  }

  onScoreIdSubmit(scoreId) {
    console.log("scoreId", scoreId, this.props.recordId)
    const data = new FormData();
    data.append('recordId', this.props.recordId);
    data.append('scoreId', scoreId);
    console.log("data", data);
    const url = "/read/score/update/record";
    fetch(url, {
      method: 'POST',
      body: data
    }).then((response) => response.json()).then((responseJson) => {
      console.log(responseJson)
      this.setConfirm(true);
    }).catch((err) => {
      console.log("Request err", err)
    })
  }

  setConfirm(status) {
    this.setState({
      confirmOpen: status
    })
  }
}
