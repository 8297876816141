import React, {
  Component
} from 'react';
import $ from 'jquery'
import "../js/jquery.shCircleLoader.js"

export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true
    };
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount() {
    this.shCircleLoader()
  }

  componentDidUpdate() {
    this.shCircleLoader()
  }

  show() {
    this.setState({
      isShow: true
    })
  }

  hide() {
    this.setState({
      isShow: false
    })
  }

  render() {
    return (
      this.state.isShow ?
      <div className="load">
        <div className="loading loading8"></div>
      </div> : null
    )
  }

  shCircleLoader() {
    $('.loading8').shCircleLoader({
      color: 'red',
      dots: 24,
      dotsRadius: 13,
      keyframes: '0%   {background: red;    {prefix}transform: scale(1)}\
        20%  {background: orange; {prefix}transform: scale(.4)}\
        40%  {background: red;    {prefix}transform: scale(0)}\
        50%  {background: red;    {prefix}transform: scale(1)}\
        70%  {background: orange; {prefix}transform: scale(.4)}\
        90%  {background: red;    {prefix}transform: scale(0)}\
        100% {background: red;    {prefix}transform: scale(1)}'
    });
  }
}
