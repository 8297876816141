import React, {
  Component
} from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import SubmitScoreId from '../components/SubmitScoreId';


export default class ImageInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      isTrue: false
    };

  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="info">
        <div className="radio-panel">
          <RadioGroup row
            aria-label="position"
            name="position"
            value={this.props.info.isTrue}
            onChange={this.handleRadioChange.bind(this)}>
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="正确"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="错误"
            />
          </RadioGroup>
        </div>
        <div className="first">
          <div className="text">
            <div><span className="info-key">合集：</span><span className="info-value">{this.props.info.collection_name}</span></div>
            <div><span className="info-key">名称：</span><span className="info-value">{this.props.info.opern_name}</span></div>
            <div className="inline">
              <span className="info-key">ScoreID：</span><span className="info-value">{this.props.info.scoreId}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="info-key">页码：</span><span className="info-value">{this.props.info.pageId}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="info-key">匹配小节：</span><span className="info-value">{this.props.info.match}</span>
            </div>
          </div>
        </div>
        <div className="second">
          {
            this.state.checked ?
             <SubmitScoreId
               onSubmit={this.props.onScoreIdSubmit}></SubmitScoreId>: null
          }
        </div>
      </div>
    )
  }

  checkedChange(event) {
    let checked = event.target.checked;
    this.setState({
      checked: checked
    });
    if (checked) {
      this.props.onScoreIdSubmit(0, 0, 0)
    } else {
      this.props.onScoreIdSubmit(0, 0, 1)
    }
  }

  handleRadioChange(event) {
    let value = event.target.value;
    this.props.onIsTrueSubmit(this.props.index, value);
  }
}
