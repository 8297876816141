import React, {
  Component
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const width = document.documentElement.clientWidth
const height = document.documentElement.clientHeight

export default class ErrorScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {

  }

  render() {
    let url = "/read/score/mq/image/view?name=" + this.props.fileName
    if (width > height) {
      return (
        <div className="image-double">
          <div className="title-panel">
            <IconButton
              id="returnUpper"
              color="primary"
              onClick={this.props.onReturnHome}>
              <ArrowBackIosIcon style={{ fontSize: 30}}></ArrowBackIosIcon>
            </IconButton>
          </div>
          <div className="double-panel double-left">
            <img src={url}  alt=""/>
          </div>
          <div className="double-panel double-right">
            <span className="errMsg">{this.props.errMsg === "success" ? '未找到此乐谱' : this.props.errMsg}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="image-single">
          <div className="title-panel">
            <IconButton
              id="returnUpper"
              color="primary"
              onClick={this.props.onReturnHome}>
              <ArrowBackIosIcon style={{ fontSize: 30}}></ArrowBackIosIcon>
            </IconButton>
          </div>
          <div className="image-panel">
            <img src={url}  alt=""/>
          </div>
          <div className="error-panel">
            <span className="errMsg">{this.props.errMsg === "success" ? '未找到此乐谱' : this.props.errMsg}</span>
          </div>
        </div>
      )
    }

  }
}
